<template>
  <div class="settings q-px-md">
    <div class="q-my-md">

      <!-- CADASTROS DE PRODUTOS PARA O CATALOGO -->
      <!--
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-store"
        label="Controle de Produtos para o Catálogo"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="respRowForm column">
            -- FAIXA DE CONTROLE
            <div class="btns q-mt-md row justify-center">
              <q-btn
                no-caps
                icon="fas fa-cart-plus"
                label="Novo Produto"
                @click="onNewProduct"
                style="width: 220px; background-color: var(--primary)"
                class="q-mb-md q-mx-md text-white"
              />
            </div>
            -- TABELA DE CATEGORIA
            <div class="table full-width">
              <q-table
                dense
                class="myTable"
                :rows="rowsProduct"
                :columns="colsProduct"
                row-key="name"
                :rows-per-page-options="[10, 20, 50, 100, 99999]"
                rows-per-page-label="Linhas por página: "
                :filter="filterProduct"
                no-results-label="Sem dados a serem mostrados"
                no-data-label="Sem dados a serem mostrados"
                @row-click="onEditRowProduct"
              >
                <template v-slot:top-left>
                  <q-input filled dense dark v-model="filterProduct" placeholder="Pesquisar" style="width: 150px">
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>
              </q-table>
            </div>

          </q-card-section>
        </q-card>
      </q-expansion-item>
      -->

      <!-- <q-space class="q-mt-xs" /> -->

      <!-- ALTERAÇÃO DE SENHA -->
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-key"
        label="Alterar Senha"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="respRowForm">
            <!-- NOVA SENHA -->
            <q-input
              dense
              outlined
              class="twoFields"
              v-model="newPsw"
              :type="isNewPwd ? 'password' : 'text'"
              label="Nova Senha"
              :rules="[val => val.length >= 6 || 'Favor informar senha de no mínimo 6 dígitos']"
            >
              <template v-slot:append>
                <q-icon
                  :name="isNewPwd ? 'visibility' : 'visibility_off'"
                  @click="isNewPwd = !isNewPwd"
                />
              </template>
            </q-input>
            <!-- CONFIRMAR NOVA SENHA -->
            <q-input
              dense
              outlined
              class="twoFields"
              v-model="rNewPsw"
              :type="isRNewPwd ? 'password' : 'text'"
              label="Confirmar Senha"
              :rules="[val => val.length >= 6 || 'Favor informar senha de no mínimo 6 dígitos']"
            >
              <template v-slot:append>
                <q-icon
                  :name="isRNewPwd ? 'visibility' : 'visibility_off'"
                  @click="isRNewPwd = !isRNewPwd"
                />
              </template>
            </q-input>
          </q-card-section>
          <q-card-section>
            <q-btn no-caps label="Alterar Senha" @click="onChangePsw" style="background-color: var(--primary); color: white;" />
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- CADASTROS DE CATEGORIAS -->
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-tags"
        label="Controle de Categorias"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="respRowForm column">
            
            <!-- FAIXA DE CONTROLE -->
            <div class="btns q-mt-md row justify-center">
              <q-btn
                no-caps
                icon="fas fa-tag"
                label="Nova Categoria"
                @click="onNewCategory"
                style="width: 220px; background-color: var(--primary)"
                class="q-mb-md q-mx-md text-white"
              />
            </div>

            <!-- TABELA DE CATEGORIA -->
            <div class="table">
              <q-table
                dense
                class="myTable"
                :rows="rowsCategory"
                :columns="colsCategory"
                row-key="name"
                :rows-per-page-options="[10, 20, 50, 100, 99999]"
                rows-per-page-label="Linhas por página: "
                :filter="filterCategory"
                no-results-label="Sem dados a serem mostrados"
                no-data-label="Sem dados a serem mostrados"
                @row-click="onEditRowCategory"
              >
                <template v-slot:top-left>
                  <q-input filled dense dark v-model="filterCategory" placeholder="Pesquisar" style="width: 150px">
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>
              </q-table>
            </div>

          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- ESCOLHA DE UM TEMA -->
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-palette"
        label="Escolher Tema"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="respRowForm q-gutter-sm">
            <div class="respRowForm justify-around">
              <q-radio
                v-for="opt in optTheme"
                :key="opt"
                v-model="theme"
                :val="opt.value"
                :label="opt.label"
                @click="onTheme(opt.value)"
              />
            </div>
          </q-card-section>
          <q-card-section>
            <q-btn no-caps label="Alterar Tema" @click="onChangeTheme" style="background-color: var(--primary); color: white;" />
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- MISSÃO, VISÃO E VALORES -->
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-bullseye"
        label="Missão, Visão e Valores"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="respRowForm q-gutter-sm">
            <q-input dense outlined class="threeFields" v-model="missao" type="textarea" label="Missão" />
            <q-input dense outlined class="threeFields" v-model="visao" type="textarea" label="Visão" />
            <q-input dense outlined class="threeFields" v-model="valor" type="textarea" label="Valores" />
          </q-card-section>
          <q-card-section>
            <q-btn no-caps label="Cadastrar/Alterar Dados" @click="onMVV" style="background-color: var(--primary); color: white;" />
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- CONTATOS -->
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-file-signature"
        label="Entre em Contato"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="respRowForm q-gutter-sm">
            <q-input dense outlined class="threeFields" v-model="title" type="text" label="Assunto" />
            <q-input dense outlined class="threeFields" v-model="mail" type="mail" label="E-Mail" />
            <q-input dense outlined class="threeFields" v-model="phone" type="text" label="Celular" />
          </q-card-section>
          <q-card-section class="respRowForm q-gutter-sm">
            <q-select class="oneFields" v-model="category" :options="optCategory" label="Categoria" outlined />
          </q-card-section>
          <q-card-section class="respRowForm q-gutter-sm">
            <q-input dense outlined class="oneFields" v-model="body" type="textarea" label="Mensagem" />
          </q-card-section>
          <q-card-section>
            <q-btn no-caps label="Enviar Mensagem" @click="onContact" style="background-color: var(--primary); color: white;" />
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- SOBRE -->
      <q-expansion-item
        group="settingGroup"
        icon="fas fa-info"
        label="Sobre"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section class="bg-verdeclaro">
            <q-img
              src="../../assets/logo_gestaosistema.png"
              width="30%"
            />
            <div class="text-subtitle2 text-italic text-bold">
              {{ 'Versão: ' + version }}
            </div>
            <div class="text-subtitle1 q-my-lg">
              A melhor solução de gestão para Eletrônicos.
              <br>
              Tudo ao seu alcance buscando sempre atender as
              <br>
              necessidades dos clientes e facilitar as rotinas diárias
            </div>
            
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />
    </div>

    <!-- MODAL PARA INSERÇÃO DE NOVA CATEGORIA -->
    <q-dialog v-model="modalCategory" persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm" style="width: 500px">
        <div v-if="editRow" class="title text-h5 text-bold text-center text-verde q-mb-md">
          EDITANDO CATEGORIA
        </div>
        <div v-else class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVA CATEGORIA
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields full-width" outlined v-model="newCategory" type="text" label="Categoria" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm q-mb-lg">
            <q-select 
              class="oneFields"
              v-model="typeCategory"
              :options="['Numérico', 'Genérico']" label="Tipo*" outlined
            />
          </div>

          <q-separator color="primary" spaced inset size="3px" />

          <div class="legenda">
            <div class="text-caption text-red">* O campo Armazenamento dos itens cadastrados em cada Categoria respeitará a seguinte mecânica:</div>
            <div class="text-caption text-red"><strong>Numérico:</strong> Apenas números (Ex.: 64, 128, 512, ...)</div>
            <div class="text-caption text-red"><strong>Genérico:</strong> O campo ficará livre (Ex: Fone de Ouvido, Mouse, Película, ...)</div>
          </div>

        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancelCategory" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSaveCategory" />
          <div class="q-mx-md" />
          <q-btn v-show="editRow" no-caps color="negative" label="Excluir" @click="onDeletCategory" />
        </div>
      </div>
    </q-dialog>

    <!-- MODAL PARA INSERÇÃO DE NOVOS PRODUTOS -->
    <q-dialog v-model="modalProduct" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm" style="width: 500px">
        <div v-if="editRowProduct" class="title text-h5 text-bold text-center text-verde q-mb-md">
          EDITANDO PRODUTO
        </div>
        <div v-else class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVO PRODUTO
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-select 
              class="twoFields"
              v-model="prodCategory"
              :options="description_categorys"
              label="Categoria"
              outlined
            />
            <q-input class="twoFields" outlined v-model="prodProduct" type="text" label="Descrição Produto" />
          </div>

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="twoFields" outlined v-model="prodBrand" type="text" label="Marca" />
            <q-input class="twoFields" outlined v-model="prodSize" type="text" label="Armazenamento" />
          </div>

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="prodColor" type="text" label="Cor" />
            <q-input class="threeFields" outlined v-model="prodPrice" type="number" label="Preço" />
            <q-select 
              class="threeFields"
              v-model="prodActive"
              :options="['Sim', 'Não']"
              label="Exibir Produto?"
              outlined
            />
          </div>

          <div class="q-mt-md">
            <q-editor v-model="prodInfos" min-height="5rem" max-height="10rem" />
          </div>

          <div
            class="respRowForm q-mt-sm full-width items-center justify-center"
            style="position: relative; z-index: 0;">
            <div v-show="!prodAvatar" class="full-height column items-center justify-center" style="position: absolute; z-index: -1;">
              <q-icon
                name="fas fa-box-open"
                size="80px"
                color="grey"
              />
              <div class="text-bold text-grey">Clique ou Arraste a imagem aqui...</div>
            </div>
            <div class="mydrop column" v-show="prodFirstAccessEditRow">
              <q-img
                :src="prodFirstAccessEditRow"
                class="q-mb-xs dropzone__item-thumbnail"
                spinner-color="primary"
                spinner-size="82px"
                width="130px"
                height="130px"
              />
              <div class="dropzone__item-control" @click="prodFirstAccessEditRow = null; prodAvatar = false" />
            </div>
            <DropZone
              :maxFiles="1"
              :maxFileSize="300000"
              :uploadOnDrop="true"
              :multipleUpload="false"
              :clickable="true"
              :acceptedFiles="['image']"
              :withCredentials="true"
              url="http://localhost:3000/item"
              paramName="my_Test"
              class="mydrop"
              v-show="!prodFirstAccessEditRow"
              @addedFile="onNewAvatar"
              @removedFile="onDeleteAvatar"
            />
          </div>

        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancelProduct" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSaveProduct" />
          <div class="q-mx-md" />
          <q-btn v-show="editRowProduct" no-caps color="negative" label="Excluir" @click="onDeletProduct" />
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
import { supabase } from '../../supabase';
import { Dialog } from 'quasar';
import { mapActions } from 'vuex';
import { defaultColor } from '../../helpers/defaultColor';
import { version } from '../../../package.json';
import { DropZone } from 'dropzone-vue';
import Slug from '../../helpers/Slug';
import removeCharSpecial from '../../helpers/removeCharSpecial';

export default {
  components: {
    DropZone
  },
  data() {
    return {
      version: version,
      enterprise: localStorage.getItem('enterprise'),
      // isOldPwd: true,
      isNewPwd: true,
      isRNewPwd: true,
      // oldPsw: null,
      newPsw: null,
      rNewPsw: null,
      theme: null,
      optTheme: [
        { label: 'Tema Padrão       ', value: 'default'   },
        { label: 'Tema Escuro       ', value: 'dark'      },
        { label: 'Tema Claro        ', value: 'light'     },
        { label: 'Tema Azul         ', value: 'blue'      },
        { label: 'Tema Rosa         ', value: 'pink'      },
        { label: 'Tema Ensolarado   ', value: 'sunshine'  },
        { label: 'Tema Antigo       ', value: 'older'     },
        { label: 'Tema Quente       ', value: 'hot'       },
        { label: 'Tema Vermelho     ', value: 'red'       },
        { label: 'Tema Dourado      ', value: 'gold'      },
      ],
      missao: null,
      visao: null,
      valor: null,
      title: null,
      mail: null,
      phone: null,
      body: null,
      category: null,
      optCategory: [
        'Sugestão / Reclamação / Elogíos', 'Suporte sobre a ferramenta', 'Indicação', 'Ýrea Comercial', 'Outros'
      ],

      rowsCategory: [],
      colsCategory: [
        { name: 'GE_DESCRIPTION',  align: 'center',  label: 'Descrição', field: 'GE_DESCRIPTION', sortable: true },
        { name: 'GE_TYPE',         align: 'center',  label: 'Tipo',      field: 'GE_TYPE',        sortable: true },
        { name: 'QTDADE',          align: 'center',  label: 'Qtd Venda', field: 'QTDADE',         sortable: true },
      ],
      filterCategory: null,

      //~> PARA MODAL DAS CATEGORIAS
      modalCategory: false,
      editRow: false,
      rebuild: true,
      newCategory: null,
      typeCategory: null,
      recno: null,

      //~> PARA MODAL DOS NOVOS PRODUTOS
      modalProduct: false,
      description_categorys: [],
      rowsProduct: [],
      colsProduct: [
        { name: 'category', align: 'center',  label: 'Categoria',     field: 'category',  sortable: true },
        { name: 'product',  align: 'center',  label: 'Produto',       field: 'product',   sortable: true },
        { name: 'brand',    align: 'center',  label: 'Marca',         field: 'brand',     sortable: true },
        { name: 'active',   align: 'center',  label: 'Ativo?',        field: 'active',    sortable: true },
        { name: 'sizes',    align: 'center',  label: 'Armazenamento', field: 'sizes',     sortable: true },
        { name: 'colors',   align: 'center',  label: 'Cor',           field: 'colors',    sortable: true },
        { name: 'price',    align: 'center',  label: 'Preço (R$)',         field: 'price',     sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        { name: 'infos',   align: 'center',  label: 'Informações Produto', field: 'infos', sortable: true,
          format: val => `${val === null ? '' : String(val).substr(0, 50)}...`
        },
      ],
      filterProduct: null,
      editRowProduct: false,
      prodCategory: null,
      prodProduct: null,
      prodBrand: null,
      prodSize: null,
      prodColor: null,
      prodPrice: null,
      prodAvatar: null,
      olderAvatar: null,
      prodActive: 'Sim',
      prodRecno: null,
      prodFirstAccessEditRow: null,
      prodInfos: null,


    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    ...mapActions('Functions', [ 'rpc' ]),
    ...mapActions('Querys', [ 'insertData', 'updateData', 'getDataWithoutFilters', 'getDataWithFilters' ]),
    async onChangePsw() { //~> OK
      if(
        this.newPsw == null || this.newPsw == '' || this.newPsw.length < 6 ||
        this.rNewPsw == null || this.rNewPsw == '' || this.rNewPsw.length < 6
      ) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Favor preencher corretamente todos os campos!'
        })
      } else if (
        this.newPsw !== this.rNewPsw
      ) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Favor rever senhas informadas, "Nova Senha" e "Confirmar Senha" devem ser iguais.'
        })
      } else {
        const { user, error } = await supabase
          .auth
          .update({
            password: this.newPsw
          })

        if (error) { Dialog.create({ title: 'Gestão Eletrônicos', message: error.message}) }
        if (user) { Dialog.create({ title: 'Gestão Eletrônicos', message: 'Senha alterada com sucesso!'}) }
      }

    },
    onTheme(value) { //~> OK

      let bodyStyles = document.body.style;
      switch (value) {
        case 'default':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#53906F')
          bodyStyles.setProperty('--primary', '#2E6C4A')
          bodyStyles.setProperty('--primaryDark', '#0D2E1D')
          break;
        case 'dark':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#969696')
          bodyStyles.setProperty('--primary', '#464646')
          bodyStyles.setProperty('--primaryDark', '#000000')
          break;
        case 'light':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#BBBBBB')
          bodyStyles.setProperty('--primary', '#999999')
          bodyStyles.setProperty('--primaryDark', '#8D8D8D')
          break;
        case 'blue':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#625CB3')
          bodyStyles.setProperty('--primary', '#362F93')
          bodyStyles.setProperty('--primaryDark', '#292466')
          break;
        case 'pink':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#FAE1CB')
          bodyStyles.setProperty('--primary', '#EDB5A8')
          bodyStyles.setProperty('--primaryDark', '#E38F9E')
          break;
        case 'sunshine':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#F1C095')
          bodyStyles.setProperty('--primary', '#E9B562')
          bodyStyles.setProperty('--primaryDark', '#C08752')
          break;
        case 'older':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#BD917C')
          bodyStyles.setProperty('--primary', '#966D58')
          bodyStyles.setProperty('--primaryDark', '#74503E')
          break;
        case 'hot':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#F8890A')
          bodyStyles.setProperty('--primary', '#E45151')
          bodyStyles.setProperty('--primaryDark', '#632F8D')
          break;
        case 'red':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#FA7070')
          bodyStyles.setProperty('--primary', '#FC4A4A')
          bodyStyles.setProperty('--primaryDark', '#C00000')
          break;
        case 'gold':
          //~> Cores Primárias
          bodyStyles.setProperty('--primaryLight', '#747272')
          bodyStyles.setProperty('--primary', '#A77D00')
          bodyStyles.setProperty('--primaryDark', '#404040')
          break;
      
        default:
          break;
      }

    },
    async onChangeTheme() { //~> OK
      let recno = localStorage.getItem('recnoClient');
      let theme = this.theme;

      await this.updateData({
        table: 'GE_PERFIL_USERS',
        fields: {
          'GE_DEFAULTCOLOR': theme
        },
        match: {
          'RECNO': recno
        }
      })

      localStorage.setItem('defaultColor', this.theme);

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Tema alterado com sucesso!'
      })
    },
    async onMVV() { //~> OK
      let enterprise = localStorage.getItem('enterprise');

      let data = await this.getDataWithoutFilters({
        table: 'GE_SETTINGS',
        fields: '*',
        enterprise: enterprise
      })

      if(data.length > 0) {
        await this.updateData({
          table: 'GE_SETTINGS',
          fields: {
            'GE_MISSAO': this.missao,
            'GE_VISAO': this.visao,
            'GE_VALORES': this.valor
          },
          match: {
            'GE_ENTERPRISE': enterprise
          }
        })
      } else {
        await this.insertData({
          table: 'GE_SETTINGS',
          fields: {
            'GE_MISSAO': this.missao,
            'GE_VISAO': this.visao,
            'GE_VALORES': this.valor,
            'GE_ENTERPRISE': enterprise,
            'DELETED': ' '
          }
        })
      }

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Dados gravados com sucesso!'
      })
    },
    async onContact() { //~> OK
      if(
        this.title    == null || this.title    == '' ||
        this.phone    == null || this.phone    == '' ||
        this.category == null || this.category == '' ||
        this.body     == null || this.body     == ''
      ) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Favor preencher todos os campos.'
        })
      } else {
        await this.insertData({
          table: 'GE_CONTACTS',
          fields: {
            'GE_SUBJECT':     this.title ,
            'GE_MAIL':        this.mail ,
            'GE_PHONE':       this.phone ,
            'GE_CATEGORY':    this.category ,
            'GE_MESSAGE':     this.body ,
            'GE_ENTERPRISE':  localStorage.getItem('enterprise') ,
            'DELETED':        ' ' ,
            'STATUS':         'PENDENTE',
          }
        })

        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Mensagem enviada com sucesso. Em breve entraremos em contato!'
        })

        this.title = ''
        this.mail = ''
        this.phone = ''
        this.category = ''
        this.body = ''
      }
    },
    onNewCategory() {
      this.modalCategory = true;
    },
    async onSaveCategory() {

      if(this.editRow) {
        await supabase
          .from('GE_CATEGORY')
          .update({
            GE_DESCRIPTION: this.newCategory,
            GE_TYPE: this.typeCategory
          })
          .match({
            RECNO: this.recno
          })
      } else {
        await supabase
          .from('GE_CATEGORY')
          .insert({
            GE_DESCRIPTION: this.newCategory,
            GE_TYPE: this.typeCategory,
            GE_ENTERPRISE: this.enterprise,
            DELETED: ' '
          })
      }

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Categoria cadastrada com sucesso!'
      });
      this.executeQuery();
      this.modalCategory = false;
    },
    onCancelCategory() {
      this.modalCategory = false;
    },
    onEditRowCategory(_, row) {
      if(row.GE_ENTERPRISE === 'ALL') {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Esta categoria é padrão do Sistema. Não permitida alteração!',
          cancel: false,
        })

        return
      }

      this.newCategory = row.GE_DESCRIPTION;
      this.typeCategory = row.GE_TYPE;
      this.recno = row.RECNO;

      this.editRow = true;
      this.modalCategory = true;
    },
    async onDeletCategory() {
      await supabase
        .from('GE_CATEGORY')
        .update({
          DELETED: '*'
        })
        .match({
          RECNO: this.recno
        });

      this.modalCategory = false;
      this.executeQuery()
    },
    async executeQuery() { //~> OK
      //~> PARA ALIMENTAR A TABELA DE CATEGORIAS
      let data = await this.rpc({
        function: 'category_vs_sells',
        enterprise: this.enterprise
      })

      this.rowsCategory = data.map(row => {
        return {
          GE_DESCRIPTION: row.descricao,
          GE_TYPE: row.tipo,
          GE_ENTERPRISE: row.empresa,
          RECNO: row.recno,
          QTDADE: row.qtdade
        }
      })

      this.description_categorys = this.rowsCategory.map(row => {
        return row.GE_DESCRIPTION
      })

      let catalog = await supabase
        .from('GE_CATALOG')
        .select('*')
        .match({
          DELETED: ' ',
          GE_ENTERPRISE: localStorage.getItem('enterprise')
        })

      this.rowsProduct = catalog.data.map(row => {
        return {
          category: row.GE_CATEGORY,
          product: row.GE_PRODUCT,
          brand: row.GE_BRAND,
          sizes: row.GE_SIZE,
          colors: row.GE_COLOR,
          price: row.GE_PRICE,
          active: row.GE_ACTIVE ? 'Sim' : 'Não',
          recno: row.RECNO,
          avatar: row.GE_AVATAR,
          infos: row.GE_INFOS,
        }
      })
    },
    resetFormNewProduct() {
      this.prodCategory = null
      this.prodProduct = null
      this.prodBrand = null
      this.prodSize = null
      this.prodColor = null
      this.prodPrice = null
      this.prodInfos = null
      this.prodActive = 'Sim'
      this.prodAvatar = null
    },
    onNewProduct() {
      this.resetFormNewProduct()
      this.editRowProduct = false
      this.modalProduct = true
    },
    onEditRowProduct(_, row) {
      this.editRowProduct = true

      try {
        this.prodCategory = row.category
        this.prodProduct = row.product
        this.prodBrand = row.brand
        this.prodSize = row.sizes
        this.prodColor = row.colors
        this.prodPrice = parseFloat(row.price).toFixed(2)
        this.prodActive = row.active ? 'Sim' : 'Não'
        this.prodRecno = row.recno
        this.prodInfos = row.infos
        
        const {data: avatar, error} = supabase
          .storage
          .from('catalog')
          .getPublicUrl(row.avatar)  
        
        if(!error) {
          this.prodFirstAccessEditRow = avatar.publicURL
          this.prodAvatar = row.avatar
          this.olderAvatar = row.avatar //Este será utilizado caso o cliente altere o Avatar
        } else {
          console.error(error.message);
        }

        this.modalProduct = true
        console.log(this.prodAvatar);
      } catch (error) {
        console.error(error.message)
      }
    },
    onCancelProduct() {
      this.modalProduct = false
      this.prodFirstAccessEditRow = null;
      this.onDeleteAvatar()
      this.executeQuery()
    },
    async onSaveProduct() {
      const slug = Slug(this.enterprise);

      if(this.editRowProduct) {
      
      // console.log(this.prodAvatar)

        if(this.prodAvatar.id) {
          const { data: removeOlder } = await supabase
            .storage
            .from('catalog')
            .remove([this.olderAvatar])

          if(removeOlder) {
            const { error } = await supabase
              .storage
              .from('catalog')
              .upload(`${removeCharSpecial(this.enterprise)} ___ ${removeCharSpecial(this.prodAvatar.file.name)}`, removeCharSpecial(this.prodAvatar.file))
  
            if(error) {
              Dialog.create({
                title: 'FATURE ELETRÔNICOS',
                message: `Erro ao editar o produto.<br/>Se persistir o erro informe esta mensagem para os administradores.<br/>ERROR: ${error.message}`,
                html: true              
              })

              return null;
            }
          }
        }

        const {data: dataProd, error: errProd} = await supabase
          .from('GE_CATALOG')
          .update({
            GE_CATEGORY: this.prodCategory,
            GE_PRODUCT: this.prodProduct,
            GE_BRAND: this.prodBrand,
            GE_SIZE: this.prodSize,
            GE_COLOR: this.prodColor,
            GE_PRICE: this.prodPrice,
            GE_INFOS: this.prodInfos,
            GE_ACTIVE: this.prodActive === 'Sim' ? true : false,
            GE_AVATAR: this.prodAvatar.id
              ? `${removeCharSpecial(this.enterprise)} ___ ${removeCharSpecial(this.prodAvatar.file.name)}`
              : removeCharSpecial(this.prodAvatar),
          })
          .match({
            RECNO: this.prodRecno
          })

          const message = dataProd 
            ? `O produto ${this.prodProduct} foi alterado com sucesso.`
            : `Erro ao editar o produto.<br/>Se persistir o erro informe esta mensagem para os administradores.<br/>ERROR: ${errProd}`
            
          Dialog.create({
            title: 'FATURE ELETRÔNICOS',
            message: message,
            html: true,
            ok: 'OK'
          })
          .onOk(() => {
            if(dataProd) {
              this.modalProduct = false
              this.executeQuery()
            } else {
              return ''
            }
          })

        
      } else {
        const {data: prod, error: errProd} = await supabase
          .from('GE_CATALOG')
          .insert({
            GE_CATEGORY: this.prodCategory,
            GE_PRODUCT: this.prodProduct,
            GE_BRAND: this.prodBrand,
            GE_SIZE: this.prodSize,
            GE_COLOR: this.prodColor,
            GE_PRICE: this.prodPrice,
            GE_INFOS: this.prodInfos,
            GE_ACTIVE: this.prodActive === 'Sim' ? true : false,
            GE_AVATAR: `${removeCharSpecial(this.enterprise)} ___ ${removeCharSpecial(this.prodAvatar.file.name)}`,
            GE_ENTERPRISE: this.enterprise,
            GE_SLUG: slug,
            DELETED: ' '
          })

        const {data: avatar, error: errAvatar} = await supabase
          .storage
          .from('catalog')
          .upload(`${removeCharSpecial(this.enterprise)} ___ ${removeCharSpecial(this.prodAvatar.file.name)}`, removeCharSpecial(this.prodAvatar.file))

        console.log(avatar);

        if(prod) {
          Dialog.create({
            title: 'FATURE ELETRÔNICOS',
            message: `
              O produto ${this.prodProduct} foi gravado com sucesso.
            `,
            html: true,
            // cancel: 'Não',
            // ok: 'Sim',
          })
          .onOk(() => {
            this.modalProduct = false;
            this.resetFormNewProduct();
            this.executeQuery();
          })
          // .onOk(() => {
          //   try {
          //     const element = document.querySelector('.dropzone__item-control');
          //     element.click();
          //     this.resetFormNewProduct();
          //   } catch (error) {
          //     console.error(error)
          //   }
          // })
        } else {
          Dialog.create({
            title: 'FATURE ELETRÔNICOS',
            message: `
              Erro ao gravar o produto. Refaça a gravação.
              <br />
              Caso o problema persista, informe este erro aos administradores do sistema:
              <br />
              Product: ${errProd.message}
              <br />
              Thumbnail: ${errAvatar.message}
            `,
            html: true
          })
        }
      }
    },
    onDeletProduct() {
      Dialog.create({
        title: 'FATURE ELETRÔNICOS',
        message: `
          Deseja realmente excluir o produto ${this.prodProduct}?
        `,
        html: true,
        cancel: 'Não',
        ok: 'Sim'
      })
      .onCancel(() => {
        return null
      })
      .onOk(async () => {
        const {error: errProd} = await supabase
          .from('GE_CATALOG')
          .delete()
          .match({
            RECNO: this.prodRecno
          })

        const {error: errAvatar} = await supabase
          .storage
          .from('catalog')
          .remove([this.prodAvatar])

        if(errProd) {
          Dialog.create({
            title: 'FATURE ELETRÔNICOS',
            message: `
              Erro ao excluir o produto. Refaça a exclusão.
              <br />
              Caso o problema persista, informe este erro aos administradores do sistema:
              <br />
              Product: ${errProd.message}
              <br />
              Thumbnail: ${errAvatar.message}
            `,
            html: true
          })
        } else {
          Dialog.create({
            title: 'FATURE ELETRÔNICOS',
            message: `Produto excluído com sucesso!`,
            ok: false,
            cancel: 'Fechar'
          })
          .onCancel(() => {
            this.modalProduct = false;
            this.executeQuery();
          })
        }
      })
    },
    onDeleteAvatar() {
      this.prodAvatar = null
    },
    onNewAvatar(event) {
      console.log(event);
      this.prodAvatar = event
    },
  },
  async created() {
    defaultColor();
    this.executeQuery();

    let enterprise = localStorage.getItem('enterprise');

    let data = await this.getDataWithoutFilters({
      table: 'GE_SETTINGS',
      fields: '*',
      enterprise: enterprise
    })

    this.missao = data[0].GE_MISSAO
    this.visao = data[0].GE_VISAO
    this.valor = data[0].GE_VALORES
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');

  .mydrop {
    width: 100%;
    height: 170px;
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 2px solid lightgray;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
  }

  .dropzone__item-thumbnail {
    border-radius: 20px;
    overflow: hidden;
    height: 130px;
    width: 130px;

    display: flex;
    align-items: center;
  }

  .dropzone__item-thumbnail > img {
    width: 100%;
  }

  .dropzone__details {
    max-width: 180px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    font-size: 10px;
    text-align: center;
    position: absolute;
    top: 60px;
    left: calc(50% + 70px);
    padding: 5px;
    border-radius: 5px;
  }

  .dropzone__progress {
    display: none;
  }

  .dropzone__item-controls {
    display: flex;
    justify-content: center;
  }

  .dropzone__item-control {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid red;
    color: white;
    position: relative;
  }

  .dropzone__item-control:after {
    position: absolute;
    top: -7px;
    left: 2px;
    content: "\274c";
    font-size: 12px;
    line-height: 36px;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    .dropzone__details {
      display: none;
    }
  }

</style>
